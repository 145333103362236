import styled from "styled-components"
import { SectionText } from "../Section/Section.styles"

const ProjectsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`

const ProjectText = styled(SectionText)`
  padding-bottom: 30px;
`

export { ProjectsContainer, ProjectText }
