import styled from "styled-components"
import theme from "@theme/theme"
import { FadeInUp } from "@keyframes/FadeInUp"
import { Typing } from "@keyframes/Typing"
import { Blink } from "@keyframes/Blink"
import { HandWave } from "@keyframes/HandWave"

const IntroText = styled.h1`
  font-weight: 400;
  margin: 0;
`

const IntroTextSpan = styled.span`
  display: inline-block;
  transform: translateY(30px);
  opacity: 0;
  padding: 0;
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInUp};
  line-height: 2;
  font-size: ${theme.font.lg};
`

const TypingText = styled.div`
  overflow: hidden;
  width: 0px;
  padding-right: 5px;
  border-right: 0.1em solid ${theme.colors.primary};
  white-space: nowrap;
  animation: ${Typing} 2s steps(50, end) forwards, ${Blink} 1s infinite;
  line-height: 2;
  font-size: ${theme.font.lg};
  box-sizing: border-box;
`

const TypingContainer = styled.div`
  display: inline-block;
`

const Wave = styled.span`
  display: inline-block;
  animation: ${HandWave} 2.5s infinite;
  transform-origin: 70% 70%;
  animation-delay: 0.5s;
`

const MeImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  transform: translateY(30px);
  opacity: 0;
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInUp};
`

const IntroCenter = styled.div`
  margin: auto;
  text-align: center;
  padding-top: 100px;

  & > ${IntroText} > span:nth-child(1) {
    animation-delay: 0s;
  }

  & > ${IntroText} > span:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > ${MeImage} {
    animation-delay: 0.4s;
  }
`

export {
  IntroCenter,
  IntroText,
  MeImage,
  IntroTextSpan,
  TypingText,
  TypingContainer,
  Wave,
}
