import styled from "styled-components"
import theme from "@theme/theme"

const NavbarContainer = styled.nav`
  padding: ${theme.padding.md};
  display: flex;
  justify-content: space-between;
`

const LeftSpace = styled.span`
  display: block;
  min-width: 1px;
`

export { NavbarContainer, LeftSpace }
