import { keyframes } from "styled-components"

const Typing = keyframes`
from {
    width: 0%;
}
to {
    width: 100%;
}
`

export { Typing }
