import React from "react"
import {
  ProjectInfoColumn,
  ProjectInfoContainer,
  ProjectInfoHeader,
  ProjectLink,
} from "./ProjectInfo.styles"
import { ProjectInfoProps } from "../ProjectPage/ProjectPage"

const ProjectInfo: React.FC<ProjectInfoProps> = (props) => {
  return (
    <ProjectInfoContainer>
      <ProjectInfoColumn>
        <ProjectInfoHeader>Type</ProjectInfoHeader>
        <span>{props.type}</span>
      </ProjectInfoColumn>
      <ProjectInfoColumn>
        <ProjectInfoHeader>Stack</ProjectInfoHeader>
        {props.stack.map((item) => (
          <span key={item}>{item}</span>
        ))}
      </ProjectInfoColumn>
      {props.code && (
        <ProjectInfoColumn>
          <ProjectInfoHeader>Code</ProjectInfoHeader>
          <ProjectLink
            title="Link to code repository"
            target="_blank"
            href={props.code}
          >
            Repository
          </ProjectLink>
        </ProjectInfoColumn>
      )}
      {props.live && (
        <ProjectInfoColumn>
          <ProjectInfoHeader>Live</ProjectInfoHeader>
          <ProjectLink
            target="_blank"
            title="Link to the website"
            href={props.live}
          >
            View Site
          </ProjectLink>
        </ProjectInfoColumn>
      )}
    </ProjectInfoContainer>
  )
}

export { ProjectInfo }
