import styled from "styled-components"

const AdditionalProjectsContainer = styled.div`
  padding: 80px 0px;
`

const AdditionalProjectsTitle = styled.h3`
  margin: 0;
  padding-bottom: 20px;
  font-size: 30px;
`

const ProjectItemsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
`

export {
  AdditionalProjectsContainer,
  AdditionalProjectsTitle,
  ProjectItemsContainer,
}
