import React from "react"
import { LinkProps } from "react-router-dom"
import { NavlinkA, NavListItem } from "./Navlink.style"

type NavlinkProps = {
  text: string
} & LinkProps

const Navlink: React.FunctionComponent<NavlinkProps> = ({ text, ...rest }) => {
  return (
    <NavListItem>
      <NavlinkA {...rest}>{text}</NavlinkA>
    </NavListItem>
  )
}

export default Navlink
