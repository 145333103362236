import { IconType } from "react-icons"
import {
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa"
import { FiCodesandbox } from "react-icons/fi"

type FollowMeType = {
  icon: IconType
  bg: string
  to: string
  title: string
}

export const followMe: FollowMeType[] = [
  {
    icon: FaGithub,
    bg: "#4e545a",
    to: "https://github.com/quinnvaughn",
    title: "Follow me on Github",
  },
  {
    icon: FiCodesandbox,
    bg: "#1C0B07",
    to: "https://codesandbox.io/u/quinnvaughn",
    title: "Check out my Codesandbox",
  },
  {
    icon: FaLinkedin,
    bg: "#0077ba",
    to: "https://www.linkedin.com/in/quinn-vaughn-19bb2564/",
    title: "Follow me on LinkedIn",
  },
  {
    icon: FaTwitter,
    bg: "#3299ff",
    to: "https://twitter.com/quinn_vaughn",
    title: "Follow me on Twitter",
  },
  {
    icon: FaFacebookF,
    bg: "#4267B2",
    to: "https://www.facebook.com/QuinnVaughn",
    title: "Follow me on Facebook",
  },
  {
    icon: FaInstagram,
    bg: "#c32aa3",
    to: "https://www.instagram.com/quinncvaughn/",
    title: "Follow me on Instagram",
  },
]
