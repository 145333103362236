import { useCallback } from "react"
import { useEffect } from "react"
import { useRef } from "react"
import { random } from "./random"

export const useRandomInterval = (
  callback: () => any,
  minDelay: number | null,
  maxDelay: number | null
): (() => void) => {
  const timeoutId = useRef<number | undefined>(undefined)
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  })

  useEffect(() => {
    const isEnabled =
      typeof minDelay === "number" && typeof maxDelay === "number"

    if (isEnabled) {
      const handleTick = () => {
        // isEnabled checks if min/max are numbers
        const nextTickAt = random(minDelay as number, maxDelay as number)

        timeoutId.current = window.setTimeout(() => {
          savedCallback.current()
          handleTick()
        }, nextTickAt)
      }

      handleTick()
    }

    return () => window.clearTimeout(timeoutId.current)
  }, [minDelay, maxDelay])

  const cancel = useCallback(() => {
    window.clearTimeout(timeoutId.current)
  }, [])

  return cancel
}
