import styled from "styled-components"
import theme from "@theme/theme"
import { FadeInLeft } from "@keyframes/FadeInLeft"

const WorkLocationsContainer = styled.div`
  max-width: 700px;
  margin: auto;
  color: ${theme.colors.paragraph};

  padding: ${theme.padding.md};

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  & > p:nth-child(1) {
    animation-delay: 0s;
  }

  & > p:nth-child(2) {
    animation-delay: 0.2s;
  }
`

const WorkLocationItem = styled.p`
  font-size: ${theme.font.sm};
  opacity: 0;
  transform: translateX(-30px);
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInLeft};
`

const WorkLocationLocation = styled.a`
  color: ${theme.colors.paragraph};
  text-decoration: none;
  transition: color 0.3s;
  :visited {
    color: ${theme.colors.paragraph} !important;
  }

  :hover {
    cursor: pointer;
    color: ${theme.colors.primary} !important;
    text-decoration: underline;
  }
`

export { WorkLocationsContainer, WorkLocationItem, WorkLocationLocation }
