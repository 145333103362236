import styled from "styled-components"
import theme from "@theme/theme"
import { Link } from "react-router-dom"

const NavlinksContainer = styled.ul`
  display: flex;
`

const NavlinkA = styled(Link)`
  font-size: ${theme.font.sm};
  text-decoration: none;
  color: ${theme.colors.font};
  transition: color 0.3s;

  :visited {
    color: ${theme.colors.font};
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.colors.primary};
  }
`

const NavListItem = styled.li`
  list-style: none;
  ${NavlinksContainer} & {
    padding-right: ${theme.padding.lg};
  }
`

export { NavlinkA, NavlinksContainer, NavListItem }
