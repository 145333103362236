import { Scale } from "@keyframes/Scale"
import theme, { COLORS } from "@theme/theme"
import { lighten } from "polished"
import { Link } from "react-router-dom"
import styled from "styled-components"

const ProjectRow = styled.div`
  display: flex;
`

const ProjectImage = styled.img`
  margin-right: 15px;
  border-radius: 15px;
  width: 100px;
  height: 100px;

  @media screen and (max-width: 700px) {
    width: 70px;
    height: 70px;
  }
`

const ProjectRight = styled.div`
  display: flex;
  align-items: center;
`

const ProjectTitle = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  color: ${theme.colors.font};
`

const ProjectDescription = styled.p`
  margin: 0;
`

const ProjectText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 14px;
`

const ProjectLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary};
  text-decoration: none;
  color: white;
  min-width: 40px;
  width: auto;
  height: 30px;
  padding: 0px 15px;
  border-radius: 200px;

  :visited {
    color: white;
  }

  :hover {
    background-color: ${lighten(0.1, COLORS.primary)};
    animation: 1s ease ${Scale} infinite alternate;
  }
`

export {
  ProjectRow,
  ProjectImage,
  ProjectRight,
  ProjectTitle,
  ProjectDescription,
  ProjectText,
  ProjectLink,
}
