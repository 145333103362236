import { ProjectPage } from "../components/ProjectPage/ProjectPage";
import intro from "./markdown/intro.md";
import projectPurpose from "./markdown/project-purpose.md";
import webstack from "./markdown/webstack.md";
import problems from "./markdown/problems.md";
import skills from "./markdown/skills.md";
import futureImprovements from "./markdown/future-improvements.md";
import purposeImage from "../../../assets/images/blush/blush_project_purpose.png";
import problemsImage from "../../../assets/images/blush/blush_problems.png";
import futureImprovementsImage from "../../../assets/images/blush/blush_future_improvements.png";
import skillsImage from "../../../assets/images/blush/blush_skills.png";

function BlushPage() {
	return (
		<ProjectPage
			title="Blush"
			intro={intro}
			images={[
				{ section: "purpose", src: purposeImage },
				{ section: "problems", src: problemsImage },
				{ section: "improvements", src: futureImprovementsImage },
				{ section: "skills", src: skillsImage },
			]}
			projectInfo={{
				stack: [
					"React",
					"Typescript",
					"GraphQL",
					"Node",
					"Postmark",
					"Prisma",
					"Apollo",
					"GCP",
					"React Hook Form",
					"React Router v6",
				],
				type: "Startup",
			}}
			sections={[
				{ section: "purpose", md: projectPurpose, id: 0 },
				{ md: webstack, section: "webstack", id: 1 },
				{ md: problems, section: "problems", id: 2 },
				{ md: skills, section: "skills", id: 3 },
				{ md: futureImprovements, section: "improvements", id: 4 },
			]}
		/>
	);
}

export { BlushPage };
