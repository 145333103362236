import React from "react"
import { DeepMap, FieldError } from "react-hook-form"
import {
  InputContainer,
  StyledInput,
  StyledLabel,
} from "./ContactMeInput.styles"

type Props = {
  label: string
  errors: DeepMap<any, FieldError>
  touched: DeepMap<any, true>
} & Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  "ref"
>

const ContactMeInput: React.FC<Props> = React.forwardRef(
  ({ label, errors, touched, ...rest }: Props, ref) => {
    return (
      <InputContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledInput {...rest} ref={ref as any} />
        {errors[rest.name as string] && touched[rest.name as string] && (
          <div style={{ color: "red" }}>
            {errors[rest.name as string].message}
          </div>
        )}
      </InputContainer>
    )
  }
)

export { ContactMeInput }
