import React from "react"
import { useState } from "react"
import { Sparkle } from "./Sparkle/Sparkle"
import { SparklesWrapper, ChildWrapper } from "./Sparkles.styles"
import { generateSparkle } from "./utils/generateSparkle"
import { range } from "./utils/range"
import { usePrefersReducedMotion } from "./utils/usePrefersReducedMotion"
import { useRandomInterval } from "./utils/useRandomInterval"

type SparklesProps = {
  color?: string
}

type SparkleType = {
  id: string
  createdAt: number
  color: string
  size: number
  style: React.CSSProperties
}

const DEFAULT_COLOR = "hsl(50deg, 100%, 50%)"

const Sparkles: React.FC<SparklesProps> = ({
  color = DEFAULT_COLOR,
  children,
}) => {
  const [sparkles, setSparkles] = useState<SparkleType[]>(() => {
    return range(4).map(() => generateSparkle(color))
  })

  const prefersReducedMotion = usePrefersReducedMotion()

  useRandomInterval(
    () => {
      const now = Date.now()

      const sparkle = generateSparkle(color)

      const nextSparkles = sparkles.filter((sparkle) => {
        const delta = now - sparkle.createdAt
        return delta < 750
      })

      nextSparkles.push(sparkle)

      setSparkles(nextSparkles)
    },
    prefersReducedMotion ? null : 50,
    prefersReducedMotion ? null : 450
  )

  return (
    <SparklesWrapper>
      {sparkles.map((sparkle) => (
        <Sparkle
          key={sparkle.id}
          color={sparkle.color}
          size={sparkle.size}
          style={sparkle.style}
        />
      ))}
      <ChildWrapper>{children}</ChildWrapper>
    </SparklesWrapper>
  )
}

export { Sparkles }
