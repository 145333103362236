import { keyframes } from "styled-components"

const Scale = keyframes`
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
    }

`

export { Scale }
