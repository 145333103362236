import styled from "styled-components"
import theme from "@theme/theme"

const Emphasize = styled.span`
  color: ${theme.colors.primary};
  font-size: inherit;
  font-weight: bold;
`

export default Emphasize
