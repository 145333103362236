import React from "react"
import ReactMarkdown, { ReactMarkdownOptions } from "react-markdown"
import { SectionText } from "@pages/Home/components/Section/Section.styles"
import { CodeMarkdown, LinkMarkdown } from "./CustomMarkdown.styles"

const CustomMarkdown: React.FC<ReactMarkdownOptions> = (props) => {
  const { children, ...rest } = props
  return (
    <ReactMarkdown
      {...rest}
      components={{
        p: function Paragraph({ children }) {
          return <SectionText>{children}</SectionText>
        },
        code: function Code({ children }) {
          return <CodeMarkdown>{children}</CodeMarkdown>
        },
        a: function Link({ children, node: { properties } }) {
          return (
            <LinkMarkdown target="_blank" {...properties}>
              {children}
            </LinkMarkdown>
          )
        },
      }}
    >
      {children}
    </ReactMarkdown>
  )
}

export { CustomMarkdown }
