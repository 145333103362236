import React from "react"
import Navlink from "./Navlink"
import { NavlinksContainer } from "./Navlink.style"

const Navlinks: React.FunctionComponent<{}> = () => {
  return (
    <NavlinksContainer>
      <Navlink text="About" to="#about" />
      <Navlink text="Projects" to="#projects" />
      <Navlink text="Contact" to="#contact" />
    </NavlinksContainer>
  )
}

export default Navlinks
