import React from "react"
import { SectionContainer, SectionText, SectionTitle } from "./Section.styles"

type SectionProps = {
  title: string
  id: string
  children: React.ReactNode
}

const Section: React.FunctionComponent<SectionProps> = ({
  id,
  title,
  children,
}: SectionProps) => {
  return (
    <SectionContainer id={id}>
      <SectionTitle>{title}</SectionTitle>
      <SectionText>{children}</SectionText>
    </SectionContainer>
  )
}

export default Section
