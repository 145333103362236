import { Project } from "@components/Project/Project"
import { projects } from "@data/projects"
import React from "react"
import { useMemo } from "react"
import {
  AdditionalProjectsContainer,
  AdditionalProjectsTitle,
  ProjectItemsContainer,
} from "./AdditionalProjects.styles"

type Props = {
  // to filter that out from projects.
  title: string
}

const AdditionalProjects: React.FC<Props> = (props) => {
  const filteredProjects = useMemo(
    () => projects.filter((project) => project.title !== props.title),
    [props.title]
  )
  return (
    <AdditionalProjectsContainer>
      <AdditionalProjectsTitle>Additional Projects</AdditionalProjectsTitle>
      <ProjectItemsContainer>
        {filteredProjects.map((project) => (
          <Project {...project} key={project.title} />
        ))}
      </ProjectItemsContainer>
    </AdditionalProjectsContainer>
  )
}

export { AdditionalProjects }
