import quotesPreview from "../assets/images/quotes/quotes_preview.jpg";
import gummPreview from "../assets/images/gumm/gumm_preview.jpg";
import autofillPreview from "../assets/images/autofill/autofill_preview.png";
import blushPreview from "../assets/images/blush/blush_preview.png";

export type ProjectType = {
	image: string;
	title: string;
	previewText: string;
	to: string;
};

export const projects: ProjectType[] = [
	{
		image: quotesPreview,
		title: "Quotes",
		previewText:
			"Create and save quotes from your favorite tv shows and movies.",
		to: "quotes",
	},
	{
		image: gummPreview,
		title: "Gumm",
		previewText: "Get perfect date experiences sent right to your email.",
		to: "gumm",
	},
	{
		image: autofillPreview,
		title: "Application Autofill",
		previewText:
			"Autofill the most common questions and fields on applications.",
		to: "application_autofill",
	},
	{
		image: blushPreview,
		title: "Blush",
		previewText: "Date experiences marketplace",
		to: "blush",
	},
];
