import { keyframes } from "styled-components"

const FadeInLeft = keyframes`
0% {
    transform: translateX(-30px);
}
100% {
    transform: translateX(0px);
    opacity: 1;
}
`

export { FadeInLeft }
