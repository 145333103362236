import styled from "styled-components"
import theme from "@theme/theme"
import { FadeInUp } from "@keyframes/FadeInUp"
import { FadeInLeft } from "@keyframes/FadeInLeft"

const ProjectPageContainer = styled.article`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr min(65ch, calc(100% - 64px)) 1fr;
  grid-column-gap: 32px;

  padding-bottom: 100px;

  & > * {
    grid-column: 2;
  }
`

const ProjectTitle = styled.h1`
  font-size: ${theme.font.xl};
  margin: 0;
  padding-bottom: 16px;
  opacity: 0;
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInUp};
`

const FullSizeImage = styled.img`
  width: 100%;
  opacity: 0;
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInLeft};
`

const Text = styled.p`
  margin: 0;
  color: ${theme.colors.paragraph};
  line-height: 2;
  font-size: 19px;
`

const ProjectIntro = styled(Text)``

const SectionText = styled(Text)``

const SectionTitle = styled.h2`
  margin: 20px 0px;
  font-size: ${theme.font.lg};
`

export {
  ProjectPageContainer,
  ProjectTitle,
  ProjectIntro,
  SectionText,
  SectionTitle,
  FullSizeImage,
}
