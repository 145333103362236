import styled from "styled-components"
import theme from "@theme/theme"

const FooterContainer = styled.footer`
  padding: 80px 0px;
  max-width: 1000px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledFooterLink = styled.a`
  font-size: ${theme.font.sm};
  text-decoration: none;
  color: ${theme.colors.font};
  transition: color 0.3s;

  ${FooterContainer} & {
    padding-right: ${theme.padding.lg};
  }

  :visited {
    color: ${theme.colors.font};
  }

  :hover {
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.colors.primary};
  }
`

export { FooterContainer, StyledFooterLink }
