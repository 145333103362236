import styled from "styled-components"

const SparklesWrapper = styled.span`
  position: relative;
  display: inline-block;
`

const ChildWrapper = styled.strong`
  position: relative;
  z-index: 1;
  font-weight: bold;
`

export { SparklesWrapper, ChildWrapper }
