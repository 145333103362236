import React from "react"
import { ProjectPage } from "../components/ProjectPage/ProjectPage"
import intro from "./markdown/intro.md"
import projectPurpose from "./markdown/projectPurpose.md"
import webstack from "./markdown/webstack.md"
import problems from "./markdown/problems.md"
import skills from "./markdown/skills.md"
import futureImprovements from "./markdown/futureImprovements.md"
import quotes from "../../../assets/images/quotes/quotes.jpg"
import quoteProblemOne from "../../../assets/images/quotes/quotes_problem_1.png"
import quoteProblemTwo from "../../../assets/images/quotes/quotes_problem_2.png"
import quoteReselect from "../../../assets/images/quotes/quotes_reselect.png"
import quoteRxJS from "../../../assets/images/quotes/quotes_rxjs.png"
import quoteStack from "../../../assets/images/quotes/quotes_stack.png"

const QuotesPage: React.FC<{}> = () => {
  return (
    <ProjectPage
      title="Quotes"
      intro={intro}
      images={[
        { section: "purpose", src: quotes },
        { section: "problems", src: quoteProblemOne },
        { section: "problems", src: quoteProblemTwo },
        { section: "webstack", src: quoteReselect },
        { section: "webstack", src: quoteStack },
        { section: "skills", src: quoteRxJS },
      ]}
      projectInfo={{
        stack: [
          "React",
          "styled-components",
          "reselect",
          "Typescript",
          "Formik",
          "RxJS",
        ],
        type: "Side Project",
        code: "https://github.com/quinnvaughn/quotes",
        live: "https://createaquote.netlify.app/",
      }}
      sections={[
        { section: "purpose", md: projectPurpose, id: 0 },
        { section: "webstack", md: webstack, id: 1 },
        { section: "problems", md: problems, id: 2 },
        { section: "skills", md: skills, id: 3 },
        { section: "improvements", md: futureImprovements, id: 4 },
      ]}
    />
  )
}

export { QuotesPage }
