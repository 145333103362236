import React from "react"
import { useHistory } from "react-router-dom"
import quinn from "../../assets/images/personal/quinn_logo.jpg"
import { LogoButton, LogoImage } from "./Logo.styles"

const Logo: React.FC<{}> = () => {
  const history = useHistory()
  return (
    <LogoButton
      aria-label="Go to top of home screen"
      to={"/"}
      onClick={(e) => {
        e.preventDefault()
        history.push("/")
        window.scrollTo({ behavior: "auto", top: 0 })
      }}
    >
      <LogoImage src={quinn} alt="Quinn Logo" />
    </LogoButton>
  )
}

export { Logo }
