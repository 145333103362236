import React from "react"
import { FollowLink, FollowSocialContainer } from "./FollowMe.styles"

type FollowSocialLinkProps = {
  to: string
  title: string
  backgroundColor: string
  index: number
}

const FollowSocialLink: React.FunctionComponent<FollowSocialLinkProps> = ({
  to,
  title,
  backgroundColor,
  children,
  index,
}) => {
  return (
    <FollowSocialContainer bg={backgroundColor}>
      <FollowLink
        index={index + 1}
        href={to}
        target="_blank"
        title={title}
        rel="noreferrer"
      >
        {children}
      </FollowLink>
    </FollowSocialContainer>
  )
}

export default FollowSocialLink
