import styled from "styled-components"
import { darken } from "polished"
import { FadeInUp } from "@keyframes/FadeInUp"

const FollowMeContainer = styled.ul`
  z-index: 1001;
  position: fixed;
  left: 0;
  top: calc(50% - 275px / 2);
  height: 275px;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 1150px) {
    display: none;
  }
`

type LinkProps = {
  index: number
}

const FollowLink = styled.a<LinkProps>`
  text-decoration: none;
  color: white;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: 1s ease ${(p) => p.index * 0.2}s 1 normal forwards running
    ${FadeInUp};
`

type SocialProps = {
  bg: string
}

const FollowSocialContainer = styled.li<SocialProps>`
  background-color: ${(p) => p.bg};
  text-decoration: none;
  box-sizing: border-box;

  :hover {
    cursor: pointer;
    background-color: ${(p) => darken(0.05, p.bg)};
  }
`

export { FollowMeContainer, FollowSocialContainer, FollowLink }
