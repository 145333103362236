type LinkType = {
  tabIndex: number
  to: string
  a11yTitle: string
  title: string
}

export const links: LinkType[] = [
  {
    tabIndex: 1,
    a11yTitle: "Follow me on Github",
    to: "https://github.com/quinnvaughn",
    title: "Github",
  },
  {
    tabIndex: 2,
    a11yTitle: "Check out my Codesandbox",
    to: "https://codesandbox.io/u/quinnvaughn",
    title: "Codesandbox",
  },
  {
    tabIndex: 3,
    title: "LinkedIn",
    to: "https://www.linkedin.com/in/quinn-vaughn-19bb2564/",
    a11yTitle: "Follow me on LinkedIn",
  },
  {
    tabIndex: 4,
    title: "Twitter",
    to: "https://twitter.com/quinn_vaughn",
    a11yTitle: "Follow me on Twitter",
  },
  {
    tabIndex: 5,
    title: "Facebook",
    to: "https://www.facebook.com/QuinnVaughn",
    a11yTitle: "Follow me on Facebook",
  },
  {
    tabIndex: 6,
    title: "Instagram",
    to: "https://www.instagram.com/quinncvaughn/",
    a11yTitle: "Follow me on Instagram",
  },
]
