import styled from "styled-components"
import theme from "@theme/theme"

const SectionContainer = styled.section`
  max-width: 1000px;
  margin: auto;
  padding-top: 100px;

  @media screen and (max-width: 700px) {
    padding-top: 40px;
  }
`

const SectionTitle = styled.h2`
  font-size: ${theme.font.lg};
  font-weight: 700;
`

const SectionText = styled.div`
  padding: 0;
  margin: 0;
  font-size: ${theme.font.sm};
  color: ${theme.colors.paragraph};
  line-height: 1.5;
`

export { SectionContainer, SectionTitle, SectionText }
