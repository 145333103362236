import styled, { keyframes } from "styled-components"

const growAndShrink = keyframes`
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
`

const spin = keyframes`
  from {
    tranform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }

`

const SparkleWrapper = styled.span`
  position: absolute;
  display: block;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${growAndShrink} 700ms forwards;
  }
`

const Svg = styled.svg`
  display: block;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${spin} 1000ms linear;
  }
`

export { Svg, SparkleWrapper }
