import React from "react"
import { ProjectPage } from "../components/ProjectPage/ProjectPage"
import intro from "./markdown/intro.md"
import projectPurpose from "./markdown/projectPurpose.md"
import webstack from "./markdown/webstack.md"
import problems from "./markdown/problems.md"
import skills from "./markdown/skills.md"
import improvement from "./markdown/improvement.md"

export const ApplicationAutofillPage: React.FC<{}> = () => {
  return (
    <ProjectPage
      title="Application Autofill"
      intro={intro}
      projectInfo={{
        stack: ["Typescript", "React", "styled components", "Chrome API"],
        type: "Side Project",
        code: "https://github.com/quinnvaughn/application_autofill",
        live: "https://chrome.google.com/webstore/detail/application-autofill/mifbjladnkkjolkjdljlhgdjmobajick?hl=en",
      }}
      images={[]}
      sections={[
        { section: "purpose", md: projectPurpose, id: 0 },
        { section: "webstack", md: webstack, id: 1 },
        { section: "problems", md: problems, id: 2 },
        { section: "skills", md: skills, id: 3 },
        { section: "improvements", md: improvement, id: 4 },
      ]}
    />
  )
}
