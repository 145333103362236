import styled from "styled-components"

const StyledLabel = styled.label``

const StyledInput = styled.input`
  max-width: 500px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-sizing: border-box;
  font-family: "muli", sans-serif;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 20px;
`

export { InputContainer, StyledInput, StyledLabel }
