import { followMe } from "@data/followMe"
import React from "react"
import { FollowMeContainer } from "./FollowMe.styles"
import FollowSocialIcon from "./FollowSocialIcon"
import FollowSocialLink from "./FollowSocialLink"

const FollowMe: React.FunctionComponent<{}> = () => {
  return (
    <FollowMeContainer>
      {followMe.map((follow, index) => (
        <FollowSocialLink
          index={index}
          key={follow.to}
          backgroundColor={follow.bg}
          to={follow.to}
          title={follow.title}
        >
          <FollowSocialIcon icon={follow.icon} />
        </FollowSocialLink>
      ))}
    </FollowMeContainer>
  )
}

export default FollowMe
