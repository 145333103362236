import Emphasize from "@components/Emphasize/Emphasize"
import Spacer from "@components/Spacer/Spacer"
import React from "react"
import {
  IntroCenter,
  IntroText,
  MeImage,
  IntroTextSpan,
  TypingText,
  TypingContainer,
  Wave,
} from "./Intro.styles"
import MyImage from "../../../../assets/images/personal/me.jpg"
import { Sparkles } from "@components/Sparkles/Sparkles"

const Intro: React.FC<{}> = () => {
  return (
    <IntroCenter>
      <IntroText>
        <IntroTextSpan>
          Hi! <Wave>👋🏻</Wave> I&apos;m{" "}
          <Sparkles>
            <Emphasize>Quinn Vaughn.</Emphasize>
          </Sparkles>
        </IntroTextSpan>
        <br />
        <TypingContainer>
          <TypingText>I design and build user interfaces.</TypingText>
        </TypingContainer>
      </IntroText>
      <Spacer size={40} axis="vertical" />
      <MeImage src={MyImage} />
    </IntroCenter>
  )
}

export { Intro }
