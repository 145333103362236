import styled from "styled-components"
import theme from "@theme/theme"
import { FadeInLeft } from "@keyframes/FadeInLeft"

const ProjectInfoColumn = styled.div`
  display: flex;
  margin-right: auto;
  flex-direction: column;
  word-break: break-word;
  opacity: 0;
  animation: 1s ease 0.2s 1 normal forwards running ${FadeInLeft};
`

const ProjectInfoContainer = styled.div`
  display: flex;
  padding: 20px 0px;

  & > ${ProjectInfoColumn}:nth-child(1) {
    animation-delay: 0s;
  }

  & > ${ProjectInfoColumn}:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > ${ProjectInfoColumn}:nth-child(3) {
    animation-delay: 0.4s;
  }

  & > ${ProjectInfoColumn}:nth-child(4) {
    animation-delay: 0.6s;
  }
`

const ProjectInfoHeader = styled.div`
  font-weight: 700;
  padding-bottom: 10px;
`

const ProjectLink = styled.a`
  color: ${theme.colors.primary};
  font-weight: bold;

  text-decoration: none;

  :hover {
    text-decoration: underline;
  }

  :visited {
    color: ${theme.colors.primary};
  }
`

export {
  ProjectInfoContainer,
  ProjectInfoColumn,
  ProjectInfoHeader,
  ProjectLink,
}
