import React from "react";
import { ProjectPage } from "../components/ProjectPage/ProjectPage";
import intro from "./markdown/intro.md";
import projectPurpose from "./markdown/projectPurpose.md";
import webstack from "./markdown/webstack.md";
import problems from "./markdown/problems.md";
import skills from "./markdown/skills.md";
import futureImprovements from "./markdown/futureImprovements.md";
import gummLanding from "../../../assets/images/gumm/gumm_landing_page.png";
import gummWebstackOne from "../../../assets/images/gumm/gumm_webstack_1.png";
import gummWebstackTwo from "../../../assets/images/gumm/gumm_webstack_2.png";
import gummAttributes from "../../../assets/images/gumm/gumm_attributes.png";
import gummInfo from "../../../assets/images/gumm/gumm_info.png";
import gummSkillsOne from "../../../assets/images/gumm/gumm_skills_learned_1.png";
import gummSkillsTwo from "../../../assets/images/gumm/gumm_skills_learned_2.png";

const GummPage: React.FC<{}> = () => {
	return (
		<ProjectPage
			title="Gumm"
			intro={intro}
			images={[
				{ section: "purpose", src: gummLanding },
				{ section: "webstack", src: gummWebstackOne },
				{ section: "webstack", src: gummWebstackTwo },
				{ section: "problems", src: gummAttributes },
				{ section: "problems", src: gummInfo },
				{ section: "skills", src: gummSkillsOne },
				{ section: "skills", src: gummSkillsTwo },
			]}
			projectInfo={{
				stack: ["React", "Typescript", "GraphQL", "Node", "Mailjet"],
				type: "Startup",
			}}
			sections={[
				{ section: "purpose", md: projectPurpose, id: 0 },
				{ md: webstack, section: "webstack", id: 1 },
				{ md: problems, section: "problems", id: 2 },
				{ md: skills, section: "skills", id: 3 },
				{ md: futureImprovements, section: "improvements", id: 4 },
			]}
		/>
	);
};

export { GummPage };
