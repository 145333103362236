import Home from "@pages/Home/Home";
import { ApplicationAutofillPage } from "@pages/Projects/ApplicationAutofill/ApplicationAutofillPage";
import { BlushPage } from "@pages/Projects/Blush/BlushPage";
import { GummPage } from "@pages/Projects/Gumm/GummPage";
import { QuotesPage } from "@pages/Projects/Quotes/QuotesPage";
import React from "react";
import { Switch, RouteProps, Route } from "react-router-dom";

const routesArray: RouteProps[] = [
	{
		exact: true,
		path: "/",
		component: Home,
	},
	{
		path: "/projects/gumm",
		component: GummPage,
	},
	{
		path: "/projects/quotes",
		component: QuotesPage,
	},
	{
		path: "/projects/application_autofill",
		component: ApplicationAutofillPage,
	},
	{
		path: "/projects/blush",
		component: BlushPage,
	},
];

const Routes: React.FC<{}> = () => {
	return (
		<Switch>
			{routesArray.map((route) => (
				<Route {...route} key={route.path as string} />
			))}
		</Switch>
	);
};

export { Routes };
