import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollHandler: React.FunctionComponent<{}> = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    const element = document.getElementById(location.hash.replace("#", ""))
    window.scrollTo({
      behavior: element ? "smooth" : "auto",
      top: element ? element.offsetTop : 0,
    })
  }, [location])

  return <>{children}</>
}

export default ScrollHandler
