import { keyframes } from "styled-components"

const FadeInUp = keyframes`
0% {
    transform: translateY(30px);
}
100% {
    transform: translateY(0px);
    opacity: 1;
}
`

export { FadeInUp }
