import React from "react"
import {
  InputContainer,
  StyledTextArea,
  StyledLabel,
} from "./ContactMeTextArea.styles"

type Props = {
  label: string
} & Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  "ref"
>

const ContactMeTextArea: React.FC<Props> = React.forwardRef(
  ({ label, ...rest }: Props, ref) => {
    return (
      <InputContainer>
        <StyledLabel>{label}</StyledLabel>
        <StyledTextArea {...rest} ref={ref as any} />
      </InputContainer>
    )
  }
)

export { ContactMeTextArea }
