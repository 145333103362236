import React from "react"
import Navlinks from "../Navlink/Navlinks"
import { LeftSpace, NavbarContainer } from "./Navbar.styles"

type NavbarProps = {}

const Navbar: React.FunctionComponent<NavbarProps> = () => {
  return (
    <NavbarContainer aria-label="main navigation">
      <LeftSpace aria-label="Spacer" />
      <Navlinks />
    </NavbarContainer>
  )
}

export default Navbar
