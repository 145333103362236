import React from "react"
import { projects } from "@data/projects"
import Section from "../Section/Section"
import { ProjectsContainer, ProjectText } from "./ProjectSection.styles"
import { Project } from "@components/Project/Project"

const ProjectSection: React.FunctionComponent<{}> = () => {
  return (
    <Section id="projects" title="Projects">
      <ProjectText>
        Most of my old projects are not currently live, so these are the ones I
        could get code for.
      </ProjectText>
      <ProjectsContainer>
        {projects.map((project) => (
          <Project {...project} key={project.title} />
        ))}
      </ProjectsContainer>
    </Section>
  )
}

export default ProjectSection
