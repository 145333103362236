import { ProjectType } from "@data/projects"
import React from "react"
import {
  ProjectRow,
  ProjectImage,
  ProjectRight,
  ProjectText,
  ProjectDescription,
  ProjectLink,
  ProjectTitle,
} from "./Project.styles"

type Props = ProjectType

const Project: React.FC<Props> = (props) => {
  return (
    <ProjectRow key={props.title}>
      <ProjectImage src={props.image} />
      <ProjectRight>
        <ProjectText>
          <ProjectTitle>{props.title}</ProjectTitle>
          <ProjectDescription>{props.previewText}</ProjectDescription>
        </ProjectText>
        <ProjectLink to={`/projects/${props.to}`}>View</ProjectLink>
      </ProjectRight>
    </ProjectRow>
  )
}

export { Project }
