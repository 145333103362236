import React from "react"
import { Logo } from "@components/Logo/Logo"
import { FooterContainer, StyledFooterLink } from "./Footer.styles"
import { links } from "@data/links"

type FooterLinkProps = {
  to: string
  title: string
  tabIndex: number
  a11yTitle: string
}

const FooterLink: React.FC<FooterLinkProps> = (props) => {
  return (
    <StyledFooterLink
      title={props.a11yTitle}
      tabIndex={props.tabIndex}
      target="_blank"
      href={props.to}
    >
      {props.title}
    </StyledFooterLink>
  )
}

const Footer: React.FC<{}> = () => {
  return (
    <FooterContainer>
      <Logo />
      <div aria-label="social links">
        {links.map((link) => (
          <FooterLink {...link} />
        ))}
      </div>
    </FooterContainer>
  )
}

export { Footer }
