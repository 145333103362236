import React from "react"
import FollowMe from "@components/FollowMe/FollowMe"
import { WorkLocations } from "./components/WorkLocations/WorkLocations"
import Navbar from "@components/Navbar/Navbar"
import AboutSection from "./components/AboutSection/AboutSection"
import ProjectSection from "./components/ProjectSection/ProjectSection"
import { ContactMeSection } from "./components/ContactMeSection/ContactMeSection"
import { Footer } from "./components/Footer/Footer"
import { Intro } from "./components/Intro/Intro"

const Home: React.FunctionComponent<{}> = () => {
  return (
    <>
      <header>
        <Navbar />
        <FollowMe />
      </header>
      <main>
        <WorkLocations />
        <Intro />
        <AboutSection />
        <ProjectSection />
        <ContactMeSection />
      </main>
      <Footer />
    </>
  )
}

export default Home
