import React from "react"
import ScrollHandler from "@components/ScrollHandler/ScrollHandler"
import { Routes } from "@routes/routes"
import { GlobalStyles } from "@theme/theme"

const App: React.FunctionComponent<{}> = () => {
  return (
    <>
      <GlobalStyles />
      <ScrollHandler>
        <Routes />
      </ScrollHandler>
    </>
  )
}

export default App
