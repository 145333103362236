import React from "react"
import { CustomMarkdown } from "@components/CustomMarkdown/CustomMarkdown"
import { Logo } from "@components/Logo/Logo"
import { ProjectInfo } from "../ProjectInfo/ProjectInfo"
import {
  FullSizeImage,
  ProjectPageContainer,
  ProjectTitle,
  SectionTitle,
} from "./ProjectPage.styles"
import { AdditionalProjects } from "../AdditionalProjects/AdditionalProjects"

type Props = {
  title: string
  intro: string
  sections: SectionMarkdown[]
  images?: ProjectImage[]
  projectInfo: ProjectInfoProps
}

type SectionMarkdown = {
  id: number
  md: string
  section: ProjectSection
}

type ProjectImage = {
  src: string
  section: ProjectSection
}

type ProjectSection =
  | "purpose"
  | "webstack"
  | "problems"
  | "skills"
  | "improvements"

export type ProjectInfoProps = {
  type: string
  stack: string[]
  code?: string
  live?: string
}

const ProjectPage: React.FC<Props> = (props) => {
  return (
    <ProjectPageContainer>
      <Logo />
      <ProjectTitle>{props.title}</ProjectTitle>
      <CustomMarkdown>{props.intro}</CustomMarkdown>
      <ProjectInfo {...props.projectInfo} />
      {props.images
        ?.filter((image) => image.section === "purpose")
        .map((image) => (
          <FullSizeImage
            key={image.src}
            src={image.src}
            className="full-bleed"
          />
        ))}
      <SectionTitle>Project Purpose</SectionTitle>
      {props.sections
        .filter((section) => section.section === "purpose")
        .map((section) => (
          <CustomMarkdown key={section.id}>{section.md}</CustomMarkdown>
        ))}
      <SectionTitle>Webstack and design decisions</SectionTitle>
      {props.sections
        .filter((section) => section.section === "webstack")
        .map((section) => (
          <CustomMarkdown key={section.id}>{section.md}</CustomMarkdown>
        ))}
      {props.images
        ?.filter((image) => image.section === "webstack")
        .map((image) => (
          <FullSizeImage key={image.src} src={image.src} />
        ))}
      <SectionTitle>Problems and thought process</SectionTitle>
      {props.sections
        .filter((section) => section.section === "problems")
        .map((section) => (
          <CustomMarkdown key={section.id}>{section.md}</CustomMarkdown>
        ))}
      {props.images
        ?.filter((image) => image.section === "problems")
        .map((image) => (
          <FullSizeImage key={image.src} src={image.src} />
        ))}
      <SectionTitle>Skills learned</SectionTitle>
      {props.sections
        .filter((section) => section.section === "skills")
        .map((section) => (
          <CustomMarkdown key={section.id}>{section.md}</CustomMarkdown>
        ))}
      {props.images
        ?.filter((image) => image.section === "skills")
        .map((image) => (
          <FullSizeImage key={image.src} src={image.src} />
        ))}
      <SectionTitle>Future improvements</SectionTitle>
      {props.sections
        .filter((section) => section.section === "improvements")
        .map((section) => (
          <CustomMarkdown key={section.id}>{section.md}</CustomMarkdown>
        ))}
      {props.images
        ?.filter((image) => image.section === "improvements")
        .map((image) => (
          <FullSizeImage key={image.src} src={image.src} />
        ))}
      <AdditionalProjects title={props.title} />
      <Logo />
    </ProjectPageContainer>
  )
}

export { ProjectPage }
