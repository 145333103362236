import { Rotate } from "@keyframes/Rotate"
import { Link } from "react-router-dom"
import styled from "styled-components"

const LogoImage = styled.img`
  width: 57px;
  height: 70px;

  :hover {
    cursor: pointer;
    animation: 1s ease-in-out 0s 1 normal forwards running ${Rotate};
  }
`

const LogoButton = styled(Link)`
  width: 57px;
  height: 75px;
  border: 0;
  background: transparent;
`

export { LogoImage, LogoButton }
