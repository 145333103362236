import theme from "@theme/theme"
import { keyframes } from "styled-components"

const Blink = keyframes`
from {
    border-color: transparent;
}
to {
    border-color: ${theme.colors.primary};
}
`

export { Blink }
