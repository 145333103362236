import React from "react"
import {
  WorkLocationItem,
  WorkLocationLocation,
  WorkLocationsContainer,
} from "./WorkLocations.style"

const WorkLocations: React.FunctionComponent<{}> = () => {
  return (
    <WorkLocationsContainer>
      <WorkLocationItem>
        Current location:{" "}
        <WorkLocationLocation
          href="https://www.google.com/maps/place/Westwood,+Los+Angeles,+CA/@34.0649541,-118.4578514,14z/data=!3m1!4b1!4m6!3m5!1s0x80c2bc86dafdc779:0x63e3f17ff5535356!8m2!3d34.0635016!4d-118.4455164!16zL20vMDIwdGQ2"
          target="_blank"
          rel="noreferrer"
        >
          Los Angeles, CA ☀️
        </WorkLocationLocation>
      </WorkLocationItem>
      <WorkLocationItem>
        Looking for jobs in:{" "}
        <WorkLocationLocation
          href="https://www.google.com/maps/place/Los+Angeles,+CA/@34.0207305,-118.6919274,10z/data=!3m1!4b1!4m5!3m4!1s0x80c2c75ddc27da13:0xe22fdf6f254608f4!8m2!3d34.0522342!4d-118.2436849"
          target="_blank"
          rel="noreferrer"
        >
          Los Angeles, CA ☀️
        </WorkLocationLocation>{" "}
        /{" "}
        <WorkLocationLocation
          href="https://www.google.com/maps/place/Remote,+OR+97458/@43.0059603,-123.9013456,15z/data=!3m1!4b1!4m5!3m4!1s0x54c45f47714fdd5d:0x47d11f5d0df41dfa!8m2!3d43.0059455!4d-123.8925908"
          target="_blank"
          rel="noreferrer"
        >
          Remote 💻
        </WorkLocationLocation>
      </WorkLocationItem>
    </WorkLocationsContainer>
  )
}

export { WorkLocations }
