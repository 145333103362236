import React from "react"
import Section from "../Section/Section"
import {
  ContactMeForm,
  ContactMeSubmitButton,
  ContactMeText,
} from "./ContactMe.styles"
import { ContactMeInput } from "./components/ContactMeInput/ContactMeInput"
import { ContactMeTextArea } from "./components/ContactMeTextArea/ContactMeTextArea"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import axios from "axios"
import { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import "./contactMe.css"

export interface ContactMeValues {
  name: string
  email: string
  message: string
}

const initialValues: ContactMeValues = {
  name: "",
  email: "",
  message: "",
}

const ContactMeValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required.").min(1),
  email: yup.string().email("Not a valid email.").required("Email is required"),
  message: yup.string().required("Message is required.").min(1),
})

const ContactMeSection: React.FC<{}> = () => {
  const [serverState, setServerState] = useState<{ ok: boolean; msg: string }>()
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, errors, touchedFields },
  } = useForm<ContactMeValues>({
    defaultValues: initialValues,
    resolver: yupResolver(ContactMeValidationSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  })

  const handleServerResponse = (ok: boolean, msg: string, clear?: boolean) => {
    if (clear) setServerState(undefined)
    else setServerState({ ok, msg })
  }

  const onSubmit = handleSubmit(async (data) => {
    try {
      await axios({
        method: "POST",
        url: `https://formspree.io/f/${process.env.FORMSPREE_ID}`,
        data,
      })
      reset()
      handleServerResponse(true, "Thanks!")
      setTimeout(() => {
        handleServerResponse(true, "", true)
      }, 3000)
    } catch (e) {
      handleServerResponse(false, e.response.data.error)
    }
  })

  return (
    <Section title="Contact Me" id="contact">
      <ContactMeText>
        Interested in working with me on a project or hiring me? Contact me with
        the form below.
      </ContactMeText>
      <ContactMeForm onSubmit={onSubmit} autoComplete="off">
        <ContactMeInput
          touched={touchedFields}
          errors={errors}
          label="Name"
          {...register("name")}
        />
        <ContactMeInput
          touched={touchedFields}
          errors={errors}
          label="Email"
          {...register("email")}
        />
        <ContactMeTextArea label="Message" {...register("message")} />
        <ContactMeSubmitButton
          type="submit"
          disabled={isSubmitting || !isValid}
        >
          Submit
        </ContactMeSubmitButton>
        {serverState && (
          <p className={!serverState.ok ? "errorMsg" : ""}>{serverState.msg}</p>
        )}
      </ContactMeForm>
    </Section>
  )
}

export { ContactMeSection }
