import { CustomMarkdown } from "@components/CustomMarkdown/CustomMarkdown"
import React from "react"
import Section from "../Section/Section"
import about from "./about.md"

const AboutSection: React.FunctionComponent<{}> = () => {
  return (
    <Section id="about" title="About Me">
      <CustomMarkdown>{about}</CustomMarkdown>
    </Section>
  )
}

export default AboutSection
