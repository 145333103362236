import { darken } from "polished"
import styled from "styled-components"
import theme, { COLORS } from "@theme/theme"
import { SectionText } from "../Section/Section.styles"

const ContactMeForm = styled.form`
  display: flex;
  flex-direction: column;
`

const ContactMeSubmitButton = styled.button`
  font-size: 16px;
  box-sizing: border-box;
  max-width: 500px;
  border: 0;
  border-radius: 4px;
  color: white;
  padding: 8px;
  background-color: ${theme.colors.primary};

  :hover {
    background-color: ${darken(0.05, COLORS.primary)};
    cursor: pointer;
  }

  :disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`

const ContactMeText = styled(SectionText)`
  padding-bottom: 20px;
`

export { ContactMeForm, ContactMeSubmitButton, ContactMeText }
