import styled from "styled-components"
import theme from "@theme/theme"

const CodeMarkdown = styled.code`
  white-space: nowrap;
  margin: 0 2px;
  padding: 0 5px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  border-radius: 3px;
`

const LinkMarkdown = styled.a`
  color: ${theme.colors.primary};

  :visited {
    color: ${theme.colors.primary};
  }
`

export { CodeMarkdown, LinkMarkdown }
