import React from "react"
import styled from "styled-components"
import { IconBase, IconType } from "react-icons"
import theme from "@theme/theme"

type FollowSocialIconProps = {
  icon: IconType
}

const FollowSocialIcon: React.FunctionComponent<FollowSocialIconProps> = ({
  icon,
}) => {
  return <Icon>{icon({})}</Icon>
}

const Icon = styled(IconBase)`
  color: white;
  font-size: ${theme.font.lg};
`

export default FollowSocialIcon
